import { AbstractComponent } from '@components/generic/abstract.component';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { AbstractResource } from '@resources/abstract.resource';
import { takeUntil } from 'rxjs/operators';
import { OrderManagerResource } from '../../order-manager.resource';
import { SnackbarService } from '@components/snackbar';
import { BASE_URL_INVOICE_ORDER, BUSINESS_OBJECT_TRANSACTION, INDICATOR_TRANSACTION_TYPE } from '@constants';
import { OrderResource} from '@resources';
import { IndicatorResource } from '@resources/indicator.resource';

@Component({
  selector: 'app-order-manager-payment-form',
  template: require('./order-manager-payment-form.component.html'),
  styles: [require('./order-manager-payment-form.component.scss'), require('../../../generic/indicator/indicator.component.scss')],
  providers: [
    {provide: AbstractResource, useClass: OrderManagerResource},
    IndicatorResource
  ],
})
export class OrderManagerPaymentFormComponent extends AbstractComponent implements OnInit {

  @Input() order: any;
  @Input() orderEntityCommercialCurrency: any;
  transactionIndicators: { [transactionId: string]: string } = {};
  paymentsRefunds: any;
  public BASE_URL_INVOICE_ORDER = BASE_URL_INVOICE_ORDER;
  public openRefund: boolean = false;
  public BUSINESS_OBJECT_TRANSACTION: string = BUSINESS_OBJECT_TRANSACTION;
  public INDICATOR_TRANSACTION_TYPE: string = INDICATOR_TRANSACTION_TYPE;

  public transactionIndicator: any;
  public opened = false;

  public refundIdToEdit: any;

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    private indicatorResource: IndicatorResource,
    @Inject('StateService') state: ng.ui.IStateService,
    @Inject('DialogService') private dialog: any,
    private snackbar: SnackbarService
  ) {
    super($translate, authService, resource, state);
  }

  ngOnInit() {
    this.fetchPaymentsRefunds();
  }

  private fetchPaymentsRefunds(): void {
    this.resource.getPaymentsRefunds(this.order.id)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response: any) => {
        this.paymentsRefunds = response;
        this.fetchTransactionState();

        if (this.state.params.refund) {
          this.toggleRefund();
        }
      })
    ;
  }

  protected fetchTransactionState(): void {
    this.paymentsRefunds.transactions.forEach((transaction: any) => {
      const transactionId = transaction['@id'].split('/').pop();
      this.indicatorResource.get(transactionId,
        {blocking: false, params: {businessObject: BUSINESS_OBJECT_TRANSACTION, type: INDICATOR_TRANSACTION_TYPE}})
        .pipe(takeUntil(this.destroyed$))
        .subscribe((data: any) => {
          this.transactionIndicators[transactionId] = data.status;
        });
    });
  }

  public getQuantity(item: any): number {
    let total = 0;

    if (this.paymentsRefunds) {
      this.paymentsRefunds.transactions.forEach((transaction: any) => {
        if (transaction.operation === 'debit' && this.transactionIndicators) {
          transaction.orderItems.forEach((orderItem: any) => {
            // tslint:disable-next-line:triple-equals
            if (this.getOrderItem(orderItem) == item.id) {
              if (this.transactionIndicators[transaction['@id'].split('/').pop()] === 'green') {
                total += orderItem.quantity;
              }
            }
          });
        }
      });
    }

    return total;
  }

  public getOrderItem(orderItem: any): number {
    return orderItem.orderItem['@id'].split('/').pop();
  }

  public toggleRefund(): void {
    this.openRefund = !this.openRefund;

    if (!this.openRefund) {
      this.refundIdToEdit = undefined;
    }
  }

  public getTransactionId(transaction: any) {
    try {
      return transaction['@id'].split('/').pop();
    } catch (error) {
      console.error(error);
    }
  }

  public goToProductEdit(event: MouseEvent, productUri: string) {
    const id = productUri.split('/').pop();
    let state = 'product.edit';

    if (this.order.detached) {
        state = 'spare-part.edit';
    }

    if (event.ctrlKey) {
        window.open(this.state.href(state, { id }, { absolute: true }), '_blank');
        return;
    }
    this.state.go(state, { id });
  }

  public setTransactionIndicator($eventIndicator: any) {
    this.transactionIndicator = $eventIndicator;
  }

  public openDateDialog() {
    this.opened = true;
  }

  setPaymentLink() {
    const body: any = { 'order': this.order['@id'] };
    this.dialog.confirm(this.translate('ALERTS.CONFIRM.BULK_SEND_PAYMENT_LINK')).then(() => {
      (<OrderResource>this.resource).create(body, { entryPoint: '/v2/order-manager/manual-send-payment-link' })
            .pipe(takeUntil(this.destroyed$))
            .subscribe(
              () => {
                this.snackbar.validate(this.translate('PAGE.ORDER_MANAGER.PAYMENT.SEND_PAYMENT_LINK'));
                this.state.go(this.state.current, this.state.params, { reload: true });
            }
      );
    });
  }

  public discountOnCart(): any[] {
    const discountCart: any = [];
    let totalDiscount = 0;
    if (!Array.isArray(this.order.discountDetail)) {
      if (this.order.discountDetail.carts.length > 0) {
        this.order.discountDetail.carts.forEach((discount: any) => {
          discountCart.push({amount: discount.discount, code: discount.discountCode});
          totalDiscount += discount.discount;
        });
      }

      if (this.order.discountDetail.automatics && this.order.discountDetail.automatics.length > 0) {
        this.order.discountDetail.automatics.forEach((discount: any) => {
          discountCart.push({amount: discount.discount, code: 'PALIER'});
          totalDiscount += discount.discount;
        });
      }

      if (this.order.discountDetail.products.length > 0) {
        this.order.discountDetail.products.forEach((discount: any) => {
          totalDiscount += discount.totalDiscount;
        });
      }
    }

    if (this.order.discount && this.order.discount - totalDiscount > 0) {
      discountCart.push({amount: this.order.discount - totalDiscount});
    }

    return discountCart;
  }

  public codes(sku: string): any[] {
    const discountCode: any = [];

    if (!Array.isArray(this.order.discountDetail)) {
      if (this.order.discountDetail.products.length > 0) {
        this.order.discountDetail.products.forEach((discount: any) => {
          if (discount.sku === sku) {
            discountCode.push({discount: discount.totalDiscount, code: discount.discountCode});
          }
        });
      }

      if (this.order.discountDetail.gifts.length > 0) {
        this.order.discountDetail.gifts.forEach((discount: any) => {
          if (discount.sku === sku) {
            discountCode.push({discount: 'CADEAU', code: discount.discountCode});
          }
        });
      }
    }

    return discountCode;
  }

  public totalPrice(): number {
    return this.order.items.reduce((total: number, item: any) =>
      total + item.quantity * (item.unitPrice + item.deee + item.ecotax), 0);
  }

  public affiliateTotalPrice(): number {
    return this.order.items.reduce((total: number, item: any) =>
      total + item.quantity * (item.affiliateUnitPrice + item.affiliateDeee + item.affiliateEcotax), 0);
  }

  public totalDiscount(): number {
    const itemDiscounts = this.order.items.reduce((total: number, item: any) =>
      total + this.totalDiscountItem(item), 0);

    const cartDiscounts = this.discountOnCart().reduce((total: number, discount: any) =>
      total + discount.amount, 0);

    return itemDiscounts + cartDiscounts;
  }

  public affiliateTotalDiscount(): number {
    const itemDiscounts = this.order.items.reduce((total: number, item: any) =>
      total + this.affiliateTotalDiscountItem(item), 0);

    const cartDiscounts = this.discountOnCart().reduce((total: number, discount: any) =>
      total + discount.amount, 0);

    return itemDiscounts + cartDiscounts;
  }


  public totalDiscountItem(item: any): number {
    const productDiscounts = this.codes(item.product.sku).reduce((total, discountProduct: any) =>
      total + discountProduct.discount, 0);

    return productDiscounts + item.discount;
  }

  public affiliateTotalDiscountItem(item: any): number {
    const productDiscounts = this.codes(item.product.sku).reduce((total, discountProduct: any) =>
      total + discountProduct.discount, 0);

    return productDiscounts + item.affiliateDiscount;
  }

  public totalFidelity(): number {
    return this.order.items.reduce((total: number, item: any) =>
      total + item.fidelity, 0);
  }

  public totalShippingPrice(): number {
    const itemShippingPrices = this.order.items.reduce((total: number, item: any) =>
      total + item.shippingPrice, 0);

    if ((this.order.shippingFeeAll + itemShippingPrices) === this.order.shippingPriceOrder) {
      return this.order.shippingPriceOrder;
    }

    if (itemShippingPrices === this.order.shippingPriceOrder && this.order.shippingFeeAll === 0) {
      return itemShippingPrices;
    }

    return itemShippingPrices + this.order.shippingPriceOrder;
  }
  public totalServices(): number {
    return this.order.totalServiceAmount;
  }

  public totalOrderShippingPrice(): number {
    const itemShippingPrices = this.order.items.reduce((total: number, item: any) =>
      total + item.shippingPrice, 0);

    if ((this.order.shippingFeeAll + itemShippingPrices) === this.order.shippingPriceOrder) {
      return this.order.shippingFeeAll;
    }

    if (itemShippingPrices === this.order.shippingPriceOrder && this.order.shippingFeeAll === 0) {
      return 0;
    }

    return this.order.shippingPriceOrder;
  }

  public affiliateTotalShippingPrice(): number {
    const affiliateItemShippingPrices = this.order.items.reduce((total: number, item: any) =>
      total + item.affiliateShippingPrice, 0);

    if ((this.order.shippingFeeAll + affiliateItemShippingPrices) === this.order.affiliateShippingPriceOrder) {
      return this.order.shippingPriceOrder;
    }

    if (affiliateItemShippingPrices === this.order.affiliateShippingPriceOrder && this.order.shippingFeeAll === 0) {
      return affiliateItemShippingPrices;
    }

    return affiliateItemShippingPrices + this.order.affiliateShippingPriceOrder;
  }

  public totalAffiliateOrderShippingPrice(): number {
    const affiliateItemShippingPrices = this.order.items.reduce((total: number, item: any) =>
      total + item.affiliateShippingPrice, 0);

    if ((this.order.shippingFeeAll + affiliateItemShippingPrices) === this.order.affiliateShippingPriceOrder) {
      return this.order.shippingFeeAll;
    }

    if (affiliateItemShippingPrices === this.order.affiliateShippingPriceOrder && this.order.shippingFeeAll === 0) {
      return 0;
    }

    return this.order.affiliateShippingPriceOrder;
  }

  public hasDebitTransaction(): boolean {
    return this.paymentsRefunds.transactions.some((transaction: any) => transaction.operation === 'debit');
  }

  public getTransactionName(transaction: any): string {
    const transactionName = `DATA.PAYMENT_TYPES.${transaction.provider}`.toUpperCase();
    const translatedTransactionName = this.$translate.instant(transactionName);
    return translatedTransactionName === transactionName ? transaction.provider : translatedTransactionName;
  }

}
